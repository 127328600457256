<template>
  <div>

    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <pre class="m-0">{{ error }}</pre>
    </b-alert>

    <b-container fluid class="mb-5">

      <b-row>
        <b-col md="6">

          <b-overlay :show="loading" class="py-4">
            <h2 class="mb-4 font-weight-bold">Your Profile</h2>
            <div class="profile-info-panel">
              <div class="h5 font-weight-bold">{{ user.name }} <b-icon icon="pencil-fill" variant="info" @click="startEditing()" style="cursor:pointer;"></b-icon></div>
              <span>{{ user.email }}</span><br/>
              <span>{{ user.timezone }}</span><br/>
            </div>
          </b-overlay>

          <b-overlay :show="filterLoading" class="py-4">
            <b-row class="mb-1">
              <b-col>
                <h3 class="font-weight-bold">Alerts & Filters</h3>
              </b-col>
              <b-col class="text-right">
                <b-button v-if="selectedEmailAlerts.length > 0" variant="danger" class="mr-2" @click="deleteSelectedEmailAlerts()">Delete</b-button>
                <b-button variant="lightgreen" @click="addNewEmailAlert()">Add</b-button>
              </b-col>
            </b-row>
            <b-table
              :items="userFilters"
              :fields="emailAlertFields"
              show-empty
              selectable
              class="filtersTable"
              @row-selected="onEmailAlertSelected"
            >
              <template #cell(is_selected)="row">
                <b-form-checkbox v-model="row.rowSelected" @change="v => v ? row.selectRow() : row.unselectRow()">{{ row.item.name }}</b-form-checkbox>
              </template>
              <template #cell(actions)="row">
                <b-link class="text-lightgreen" @click="startFilterEditing(row.item)"><u>Edit</u></b-link>
              </template>
            </b-table>
          </b-overlay>

        </b-col>
        <b-col md="6">

          <b-form @submit="onSubmit" @reset="onReset" v-if="editing" class="sidebarForm">
            <b-overlay :show="loading" class="formContent">
              <b-form-group
                label="Full Name"
              >
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Email Address"
              >
                <b-form-input
                  v-model="form.email"
                  type="email"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Time Zone"
              >
                <b-form-select
                  v-model="form.timezone"
                  :options="timezones"
                  required
                ></b-form-select>
              </b-form-group>
              <b-row>
                <b-col class="text-right">
                  <b-button class="mr-2" @click="cancelEditing" variant="danger">Cancel</b-button>
                  <b-button type="submit" variant="success" :disabled="!isProfileUpdated">Save</b-button>
                  <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>

          <b-form @submit="onSubmitFilter" @reset="onResetFilter" v-if="filterEditing" class="sidebarForm">
            <b-overlay :show="filterLoading" class="formContent">
              <b-button variant="link" class="float-right text-lightgreen" v-if="filterForm.id" @click="removeFilter()"><u>Delete</u></b-button>
              <h3>
                <span v-if="filterForm.id">Edit </span>
                <span v-else>New </span>
                <span>Filter & Email Alert</span>
              </h3>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Filter/Alert Label"
                  >
                    <b-form-input
                      v-model="filterForm.name"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <strong class="mr-2">Email Alert Frequency: </strong>
                  <b-form-checkbox v-model="filterForm.meta.is_live" inline>Live Alerts</b-form-checkbox>
                  <b-form-checkbox v-model="filterForm.meta.is_daily" inline>Daily Summary</b-form-checkbox>
                  <b-form-checkbox v-model="filterForm.meta.is_weekly" inline>Weekly Report</b-form-checkbox>
                  <p class="small"><strong>Not receiving alerts?</strong> Search your inbox for subject line "ProspectorIQ SixAxis"</p>
                </b-col>
              </b-row>
              <template
                v-for="(cond, index) in filterForm.data"
              >
                <b-row
                  :key="index"
                  :class="{'mt-4': index == 0, 'mt-2': index > 0}"
                >
                  <b-col cols="auto">
                    <div class="mt-2">
                      <b-link @click="deleteFilterCondition(index)"><b-icon icon="dash-circle-fill" variant="danger"></b-icon></b-link>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <b-form-select v-model="cond.key" :options="availableCriteria(cond.key)" @change="cond.value = cond.key == 'industries' ? [] : ''">
                      <template #first>
                        <b-form-select-option :value="''" disabled>-- Please select an option --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-col>
                  <b-col>
                    <template v-if="cond.key == 'industries'">
                      <treeselect
                        v-if="Array.isArray(cond.value)"
                        v-model="cond.value"
                        :multiple="true"
                        :options="industryOptions"
                        :show-count="true"
                        class="filter-industries"
                        placeholder="Select Industries"
                      >
                      </treeselect>
                    </template>
                    <template v-else-if="cond.key != ''">
                      <b-form-input v-if="!Array.isArray(cond.value)" v-model="cond.value"></b-form-input>
                    </template>
                  </b-col>
                </b-row>
                <b-row
                  :key="index + '_suffix'"
                  v-if="index != filterForm.data.length - 1"
                  class="mt-2"
                >
                  <b-col>
                    <strong>AND</strong>
                  </b-col>
                </b-row>
              </template>
              <b-row class="mt-4">
                <b-col>
                  <b-link class="text-decoration-none text-dark" @click="addNewFilterCondition()"><b-icon icon="plus-circle" class="mr-2"></b-icon><strong>ADD</strong></b-link>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col class="text-right">
                  <b-button variant="link" class="text-lightgreen mr-4" @click="cancelFilterEditing"><u>Cancel</u></b-button>
                  <b-button type="submit" variant="lightgreen" class="px-5">Save</b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  data() {
    return {
      form: {
        email: '',
        name: '',
        timezone: this.moment.tz.guess(),
      },
      editing: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      timezones: [
        { value: null, text: 'Please select an option', disabled: true },
        ...this.moment.tz.zonesForCountry('US', true).sort((a, b) => a.offset - b.offset).map(zone => ({ value: zone.name, text: zone.name }))
      ],

      filterEditing: false,
      filterForm: {
        id: null,
        name: '',
        type: '',
        meta: {},
        data: [],
      },
      selectedEmailAlerts: [],
      emailAlertFields: [
        {
          key: 'is_selected',
          label: '',
          class: 'text-nowrap',
        },
        {
          key: 'meta',
          label: '',
          formatter: (value) => this.formatter_filterMeta(value),
          class: 'w-100',
        },
        {
          key: 'actions',
          label: '',
        }
      ],
      filterMetas: {
        is_live: 'Live Alerts',
        is_daily: 'Daily Summary',
        is_weekly: 'Weekly Report',
      },
      criteria: [
        { value: 'companyName', text: 'Company' },
        { value: 'city_or_state', text: 'City/State' },
        { value: 'industries', text: 'Industries' },
        { value: 'country', text: 'Country' },
        { value: 'page_keyword', text: 'Page Keyword or URL' },
        { value: 'zipcodes', text: 'Zip' },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'loading',
      'filterLoading',
      'error',
      'userFilters',
    ]),
    ...mapGetters('settings/industries', {
      industry_codes: 'industry_codes',
      industryLoading: 'loading',
    }),
    industryOptions: function() {
      const options = []
      this.industry_codes.forEach(el => {
        const node = {
          id: el.Id,
          label: el.Name,
        }
        const parts = el.Id.split('.')
        if (parts.length == 1) {
          options.push(node)
        } else {
          const parentOption = options.find(option => option.id == parts[0])
          if (parentOption) {
            if (!parentOption.children) parentOption.children = []
            parentOption.children.push(node)
          }
        }
      })
      return options
    },
    isProfileUpdated: function() {
      return this.form.name != this.user.name || this.form.email != this.user.email || this.form.timezone != this.user.timezone
    },
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      try {
        await this.$store.dispatch('auth/update', this.form)
        this.cancelEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    onReset(event) {
      event && event.preventDefault()
      this.form.name = this.user.name
      this.form.email = this.user.email
      this.form.timezone = this.user.timezone
      this.editing = false
      this.$nextTick(() => {
        this.editing = true
      })
    },
    cancelEditing() {
      this.editing = false
    },
    startEditing() {
      this.form.name = this.user.name
      this.form.email = this.user.email
      this.form.timezone = this.user.timezone
      this.editing = true
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    async onSubmitFilter(event) {
      event.preventDefault()
      try {
        await this.$store.dispatch('auth/submitFilter', this.filterForm)
        this.cancelFilterEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    onResetFilter(event) {
      event && event.preventDefault()
      this.filterForm.name = ''
      this.filterForm.type = ''
      this.filterForm.meta = {}
      this.filterForm.data = []
      this.filterEditing = false
      this.$nextTick(() => {
        this.filterEditing = true
      })
    },
    cancelFilterEditing() {
      this.filterEditing = false
    },
    addNewEmailAlert() {
      this.filterForm.id = 0
      this.onResetFilter()
      this.filterForm.type = 'alert'
      this.filterForm.meta = {
        is_live: false,
        is_daily: false,
        is_weekly: false,
      }
    },
    startFilterEditing(filter) {
      this.filterForm.id = filter.id
      this.filterForm.name = filter.name
      this.filterForm.type = filter.type
      this.filterForm.meta = filter.meta
      this.filterForm.data = filter.data
      this.filterEditing = true
    },
    formatter_filterMeta(meta) {
      return Object.keys(this.filterMetas).filter(key => meta[key]).map(key => this.filterMetas[key]).join(', ')
    },
    async removeFilter() {
      if (!confirm('Are you sure you want to delete this filter?')) return
      try {
        await this.$store.dispatch('auth/deleteFilter', this.filterForm)
        this.cancelFilterEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    onEmailAlertSelected(items) {
      this.selectedEmailAlerts = items
    },
    async deleteSelectedEmailAlerts() {
      if (!confirm('Are you sure you want to delete these email alerts?')) return
      try {
        this.cancelFilterEditing()
        await this.$store.dispatch('auth/deleteFilters', this.selectedEmailAlerts.map(filter => filter.id))
      } catch(e) {
        this.showAlert()
      }
    },
    addNewFilterCondition() {
      this.filterForm.data.push({
        key: '',
        value: null,
      })
    },
    deleteFilterCondition(index) {
      this.filterForm.data.splice(index, 1)
    },
    availableCriteria(condKey) {
      return this.criteria.filter(c => !this.filterForm.data.find(cond => cond.key == c.value) || condKey == c.value)
    },
  }
}
</script>
