<template>
  <div class="page-profile">
    <Profile />
  </div>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'ProfileView',
  components: {
    Profile,
  },
}
</script>